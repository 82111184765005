.label-container {
    margin-top: 80px;
    font-size: 2rem;
    text-align: center;
    color: #333;
}

.photos-container {
    padding: 20px 0;
}

.photos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    position: relative;
}

.photo-wrapper {
    position: relative;
    width: calc(33.333% - 20px); 
    height: 250px; 
    overflow: hidden;
}

.photo-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.photo-wrapper img:hover {
    transform: scale(1.05);
}

.delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(0, 123, 255, 0.8);
    color: white;
    font-size: 18px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-icon:hover {
    background-color: rgba(0, 123, 255, 1);
}

@media only screen and (max-width: 768px) {
    .label-container {
        font-size: 1.5rem;
        margin-top: 40px;
    }

    .photos {
        gap: 15px;
    }

    .photo-wrapper {
        width: calc(50% - 10px);
        height: 200px;
    }
}

@media only screen and (max-width: 480px) {
    .label-container {
        font-size: 1.2rem;
        margin-top: 30px;
    }

    .photo-wrapper {
        width: 100%;
        height: 180px;
    }

    .photos {
        gap: 10px;
    }
}
