.card {
    padding: 10px;
    box-shadow: 0 0 5px #ffdfdf;
    border-radius: 5px;
    overflow: hidden;
}

.card .top {
    text-align: center;
}

.card p {
    font-weight: bold;
    color: #0086fe;
}

.card button {
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background: #0086fe;
}

.card .drag-area {
    height: 150px;
    border-radius: 5px;
    border: 2px dashed #0086fe;
    color: #0086fe;
    background: #f4f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    margin-top: 10px;
}

.card .drag-area .visible {
    font-size: 18px;
}

.card .select {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}

.card .select:hover {
    opacity: 0.6;
}

.card .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}

.card .container .image {
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}

.card .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.card .container .image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}

.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
    display: none;
}

.delete {
    z-index: 999;
    color: #0086fe;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.start-50 {
    left: 50%;
}

.translate-middle-x {
    transform: translateX(-50%);
}

@media only screen and (max-width: 768px) {
    .card {
        padding: 8px;
    }

    .card button {
        padding: 7px 10px;
        font-size: 14px;
    }

    .card .drag-area {
        height: 120px;
        font-size: 14px;
    }

    .card .drag-area .visible {
        font-size: 16px;
    }

    .card .container .image {
        width: 60px;
        height: 60px;
    }

    .card .container {
        max-height: 150px;
        gap: 5px;
    }

    .card .container .image span {
        top: -5px;
        right: 5px;
        font-size: 16px;
    }

    .card p {
        font-size: 14px;
    }

    .card .select {
        font-size: 14px;
    }
}