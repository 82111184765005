h2 {
    text-align: center;
    color: #333;
    font-weight: 400;
    margin-bottom: 2px;
}

.editing-row {
    background-color: #f0f8ff; 
    border: 2px solid #007bff; 
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  