.summary-container {
  background-color: #f4f4f4;
  border-radius: 12px;
  padding: 30px;
  max-width: 1100px;
  margin: 20px auto;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
}

.summary {
  display: flex;
  justify-content: space-between;
  gap: 20px; 
}

.summary-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  gap: 20px;
}

.summary-item {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.summary-item h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.summary-item p {
  font-size: 16px;
  margin: 10px 0 0;
  color: #666;
}

.summary-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
}

.recent-customers-container {
  background-color: #f9f9f9; 
  border-radius: 12px;
  padding: 30px;
  max-width: 1100px;
  margin: 40px auto;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1); 
  color: #333; 
}

.recent-customers {
  background-color: #fff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05); 
}

.recent-customers h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333; 
}

.recent-customers ul {
  padding-left: 20px;
  list-style-type: none;
}

.recent-customers li {
  font-size: 17px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
  padding: 5px 10px 10px 10px;
}

.recent-customers li:last-child {
  border-bottom: none;
}

.transaction-date {
  color: #666; 
  width: 20%;
}

.transaction-product {
  width: 30%;
  font-weight: bold;
}

.transaction-customer {
  width: 25%;
}

.transaction-price {
  color: #28a745; 
  font-weight: bold;
  width: 15%;
  text-align: right;
}

.recent-customers li:hover {
  background-color: rgba(0, 0, 0, 0.05); 
  transition: background-color 0.3s ease;
}

.transaction-header {
  font-weight: bold;
  font-size: 18px;
  background-color: #f9f9f9; 
  padding: 10px; /* Néhány padding a szöveg körül */
  border-radius: 8px; /* Lekerekített sarkok */
}

.profit-summary-green {
  color: #2ecc71!important;
  font-weight: bold;
}

.profit-summary-red {
  color: #e74c3c!important;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .summary {
    flex-direction: column;
  }

  .summary-column {
    gap: 10px;
  }

  .summary-item {
    padding: 20px;
  }

  .summary-item h3 {
    font-size: 16px;
  }

  .summary-item p {
    font-size: 14px;
  }

  .recent-customers-container {
    padding: 20px;
  }

  .recent-customers {
    padding: 15px;
  }

  .recent-customers h3 {
    font-size: 18px;
  }

  .recent-customers ul {
    padding-left: 10px;
  }

  .recent-customers li {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .transaction-date,
  .transaction-product,
  .transaction-customer,
  .transaction-price {
    width: 100%;
    font-size: 14px;
  }

  .transaction-price {
    text-align: left;
  }
  
  .transaction-header {
    display: none!important;
  }
}
