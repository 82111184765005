.navbar {
    width: 100%;
    font-family: inherit;
    font-size: 18px;
    background-color: #2c3e50;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%; 
    box-sizing: border-box;
}

.navbar-brand {
    font-size: 19px;
    font-weight: 580;
    text-decoration: none;
    color: #ffffff;
    padding: 15px 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background-color 0.3s, color 0.3s;
}

.navbar-brand:hover,
.navbar-brand:focus,
.navbar-brand:active {
    background-color: #304357;
    color: #ecf0f1;
}

.centered-navbar .navbar-container {
    justify-content: center;
}

.navbar-toggler {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 10px;
    display: none;
    white-space: nowrap;
    font-size: 24px;
    color: #ffffff;
}

.navbar-toggler-icon {
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    display: block;
    position: relative;
    transition: background-color 0.3s;
}

.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
    content: '';
    width: 30px;
    height: 3px;
    background-color: #ffffff;
    display: block;
    position: absolute;
    transition: transform 0.3s;
}

.navbar-toggler-icon:before {
    top: -8px;
}

.navbar-toggler-icon:after {
    top: 8px;
}

.navbar-toggler-icon.open {
    background-color: transparent;
}

.navbar-toggler-icon.open:before {
    transform: rotate(45deg) translate(5px, 5px);
}

.navbar-toggler-icon.open:after {
    transform: rotate(-45deg) translate(5px, -5px);
}

.navbar-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 50px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .navbar-toggler {
        display: block;
    }

    .navbar-container {
        justify-content: space-between;
    }

    .navbar-brand {
        font-size: 16px;
        padding: 10px 0;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .navbar-menu {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        right: 0;
        gap: 10px;
        background-color: #2c3e50;
        padding: 10px 20px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .navbar-menu.show {
        display: flex;
    }

    .navbar-menu a {
        padding: 10px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .navbar-menu a:last-child {
        border-bottom: none;
    }
}
