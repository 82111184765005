.ajanlat-container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  background-color: #f8f9fa; 
}

.form-container, .template-add-container, .template-delete-container {
  max-width: 600px;
  flex: 1;
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); 
  transition: box-shadow 0.3s ease;
}

.form-container:hover, .template-add-container:hover, .template-delete-container:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1); 
}

.form-container h1, .template-add-container h2, .template-delete-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50; 
  font-weight: 600;
}

.form-container label, .template-add-container label, .template-delete-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #7f8c8d; 
}

.form-container input, .form-container select,
.template-add-container input, .template-add-container textarea,
.template-delete-container select {
  width: calc(100% - 20px);
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #dcdde1;
  border-radius: 8px;
  font-size: 16px;
  background-color: #f8f9fa; 
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.form-container input:focus, .form-container select:focus,
.template-add-container input:focus, .template-add-container textarea:focus,
.template-delete-container select:focus {
  border-color: #3498db; 
  background-color: #ffffff; 
}

/*  */

@media (max-width: 768px) {
  .ajanlat-container {
    flex-direction: column;
    align-items: center;
  }

  .form-container, .template-add-container, .template-delete-container {
    width: 100%; 
    margin-bottom: 20px;
  }
}

.accordion-button:hover {
  background-color: #ebf5fb; 
}


#leirasAccord {
  margin-bottom: 15px;
}

.success-message {
  color: #2ecc71;
  font-weight: bold;
}

.error-message {
  color: #e74c3c;
  font-weight: bold;
}
